import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NbDialogRef } from "@nebular/theme";

@Component({
  selector: "ngx-sessao-expirada-modal",
  template: `
    <nb-card>
      <nb-card-body>
        <div class="container-modal">
          <div>
            <img class="imagem swing" src="assets/images/clock-red.png" />
          </div>
          <div class="texto">Sua Sessão Expirou!</div>
          <br />
          <button class="btn btn-danger" (click)="dismiss()">Sair</button>
        </div>
      </nb-card-body>
    </nb-card>
  `,
  styleUrls: ["./sessaoExpiradaModal.component.scss"],
})
export class ModalSessaoExpiradaComponent {
  constructor(
    protected ref: NbDialogRef<ModalSessaoExpiradaComponent>,
    public router: Router,
    public http: HttpClient
  ) {}

  dismiss() {
    sessionStorage.removeItem("actsit");
    sessionStorage.removeItem("portal");
    sessionStorage.removeItem("uid");
    sessionStorage.removeItem("x");
    window.location.reload();
  }
}
