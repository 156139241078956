import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SV_URL } from '../../../../../@core/utils/utils';
import { CambioProvider } from '../../../../../services/cambio';

@Component({
  selector: 'ngx-dialog-template3',
  templateUrl: './ruaModal.component.html',
  styleUrls: ['./ruaModal.component.scss'],
})
export class RuaTemplateComponent implements OnInit {
  @Input() cod: string;
  @Input() data: string;

  premios = [];
  pecas = [];

  lote1 = {desc: 'PTM', valor: 0, premios: []};
  lote2 = {desc: 'PT', valor: 0, premios: []};
  lote3 = {desc: 'PV', valor: 0, premios: []};
  lote4 = {desc: 'PTN', valor: 0, premios: []};
  lote5 = {desc: 'FED', valor: 0, premios: []};
  lote6 = {desc: 'COR', valor: 0, premios: []};


  resumo = {
    pecas: 0,
    premios: 0,
    comissao: 0,
    lucro: 0,
    anterior: 0,
    envio: 0,
    final: 0,
    deu: 0,
    comret: 0,
  };


  constructor(
    protected ref: NbDialogRef<RuaTemplateComponent>,
    public http: HttpClient,
    public router: Router,
    private toastr: NbToastrService,
    private provider: CambioProvider,
    private loader: NgxUiLoaderService,
    ) {}

  dismiss() {
    this.ref.close();
  }

  ngOnInit() {
    this.getPecas();
    this.getPremios();

    this.http.get(`${SV_URL}/getCambioPosicaoFinalIndivual/${this.data}/${this.cod}`).subscribe(data => {
    });

  }

  getPecas() {
    let formatedData;
    this.resumo.pecas = 0;
    this.resumo.comissao = 0;

    this.http.get(`${SV_URL}/getPecasCod/${this.cod}`).subscribe(data => {
      let dados;
      dados = data;
      if (dados) {
        this.pecas = [];
        dados.forEach(element => {
          formatedData = element.data.slice(0, 10);
          formatedData = formatedData.slice(8, 11) + '/' + formatedData.slice(5, -3) + '/' + formatedData.slice(0, 4);
          this.pecas.push({
            'id':   element.ID,
            'data':   formatedData,
            'cli':    element.cli,
            'valor':    element.valor,
            'pc':    element.pc,
          });


          this[`lote${element.pc}`].valor += element.valor;
          this.resumo.pecas += element.valor;
          // this.calculoGeral();
        });
        this.calculoGeral();
      }

    });
  }

  getPremios() {
    let formatedData;
    this.resumo.premios = 0;

    this.http.get(`${SV_URL}/getPremiosCod/${this.cod}`).subscribe(data => {
      let dados;
      dados = data;
      if (dados) {
        this.premios = [];
        dados.forEach(element => {
          formatedData = element.data.slice(0, 10);
          formatedData = formatedData.slice(8, 11) + '/' + formatedData.slice(5, -3) + '/' + formatedData.slice(0, 4);
          this[`lote${element.pc}`].premios.push({
            'id':   element.ID,
            'data':   formatedData,
            'cli':    element.cli,
            'valor':    element.valor,
            'pc':    element.pc,
            'apos': element.apos,
          });
        });
      }
    });
  }

  premiosFiltrados(lote) {
    return this.premios.filter(item => item.pc === lote);
  }

  calculoGeral() {
    this.http.get(`${SV_URL}/getCambioPosicaoFinalIndivual/${this.data}/${this.cod}`).subscribe(data => {
      this.resumo.comissao = data['com'];
      this.resumo.lucro = data['lucro'];
      this.resumo.final = data['final'];
      this.resumo.premios = data['premios'];
      this.resumo.anterior = data['anterior'];
      this.resumo.deu = data['deu'];
      this.resumo.envio = data['envio'];
    });
  }

  // async finalizar(){
  //   var user = atob(sessionStorage.getItem('uid'));
  //   var alt = new Date();

  //   this.loader.start();
  //   await this.provider.saveCambioFolha(this.data, this.cod, this.resumo.pecas, this.resumo.premios, this.resumo.comissao, this.resumo.deu, this.resumo.envio, this.resumo.lucro, this.resumo.anterior, this.resumo.final, this.resumo.comret, user, alt);

  //   await this.provider.finalizarPecas(this.cod);
  //   await this.provider.finalizarPremios(this.cod);
  //   await this.provider.finalizarDeu(this.cod);
  //   await this.provider.finalizarEnvio(this.cod);

  //   await this.provider.atualizarAnterior(this.cod, this.resumo.final, this.resumo.comret);

  //   this.dismiss();
  //   this.loader.stop();


  // }
}
