import { SV_URL_AST } from './../@core/utils/utils';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

import { SV_URL } from '../@core/utils/utils';

@Injectable()
export class ComunicadoProvider {

  public id: string;

  constructor(public http: HttpClient) { }

  saveComunicado(tipo, msg) {
    const url: string = SV_URL + '/postComunicado';

    const postParams = {
      tipo: tipo,
      msg: msg,

    };

    this.http.post(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');
      }, error => {
        console.log(error);
      });
  }

  saveComunicadoApp(tipo, msg) {
    const url: string = SV_URL_AST + '/saveMural';

    const postParams = {
      tipo: tipo,
      texto: msg,

    };

    this.http.post(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');
      }, error => {
        console.log(error);
      });
  }

}
