import { Injectable } from "@angular/core";

@Injectable()
export class FormatUtilsProvider {
  constructor() {}

  zeroEsquerda(number, length) {
    let my_string = "" + number;
    while (my_string.length < length) {
      my_string = "0" + my_string;
    }
    return my_string;
  }
}
