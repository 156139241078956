import { SV_URL_AST } from './../@core/utils/utils';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

import { SV_URL } from '../@core/utils/utils';

@Injectable()
export class TarefasProvider {

  public id: string;

  constructor(public http: HttpClient) { }

  saveTarefa(user, solicitante, data, prioridade, descricao, titulo) {
    const url: string = SV_URL + '/postTarefa';

    const postParams = {
      user, solicitante, data, prioridade, descricao, titulo
    };

    this.http.post(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');
      }, error => {
        console.log(error);
      });
  }

  leitura(id) {
    const url: string = SV_URL + '/postLeitura';

    const postParams = {
      id
    };

    this.http.post(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');
      }, error => {
        console.log(error);
      });
  }


  conclusao(id) {
    const url: string = SV_URL + '/postConclusao';

    const postParams = {
      id
    };

    this.http.post(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');
      }, error => {
        console.log(error);
      });
  }

}
