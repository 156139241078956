import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

import { SV_URL } from '../@core/utils/utils';

@Injectable()
export class DespesasProvider {

  public id: string;

  constructor(public http: HttpClient) {}

  saveDespesas(data, cli, valor, cod, user, alt, tipo) {
    const url: string = SV_URL + '/postDespesas';

    const postParams = {
      data: data,
      cli: cli ,
      valor: valor,
      cod: cod,
      user: user,
      alt: alt,
      tipo: tipo,
    };

    this.http.post(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

  putDespesa(id, data, cli, valor, cod, user, alt, tipo) {
    const url: string = SV_URL + '/putDespesa';

    const postParams = {
      id: id,
      data: data,
      cli: cli ,
      valor: valor,
      user: user,
      alt: alt,
      cod: cod,
      tipo: tipo,
    };

    this.http.put(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

  saveDespesasSaldo(cod, valor, de, ate, user, alt) {
    const url: string = SV_URL + '/postDespesasSaldo';

    const postParams = {
      cod: cod,
      valor: valor ,
      de: de,
      ate: ate,
      user: user,
      alt: alt,
    };

    this.http.post(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

  putDevolvido(id) {
    const url: string = SV_URL + '/putDespesaCheque';

    const postParams = {
      id: id,

    };

    this.http.put(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

}
