import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http } from '@angular/http';

import { reject } from 'q';
import { Buffer } from 'buffer';
import 'rxjs/add/operator/map';

@Injectable()
export class UtilsProvider {

  constructor(
    public http: Http,
    public httpClient: HttpClient) {
  }

  encodePayload(data) {
    return { pld: btoa(JSON.stringify(data)) };
  }

  decodePayload = (data) => {
    const buff = new Buffer(data, 'base64');
    let req = buff.toString('ascii');
    return req = JSON.parse(req);
  }

  postHttp(url, data) {
    return new Promise((resolve, reject) => {
      this.http.post(url, this.encodePayload(data))
        .subscribe(res => {
          resolve(res);
        }, error => {
          console.log(error);
          reject();
        });
    });
  }

  postHttpClient(url, data, decode = false) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(url, data)
        .subscribe(res => {
          if (decode) {
            resolve(this.decodePayload(res['pld']));
          } else {
            resolve(res);
          }
        }, error => {
          reject(error);
        });
    });
  }

  post(url, data) {
    return this.httpClient.post(url, data);
  }

  get(url) {
    return this.httpClient.get(url);
  }

  getHttpClient(url, data) {
    return this.httpClient.post(url, data);
  }

}

