import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class EncrDecrService {
  constructor() {}

  private keys = '123456$#@$^@1ERF';
  // The set method is use for encrypt the value.
  set(value) {
    const data = JSON.stringify(value);
    const encrypted = CryptoJS.AES.encrypt(data, this.keys);
    return encrypted.toString();
  }

  // The get method is use for decrypt the value.
  get(value) {
    const decrypted = CryptoJS.AES.decrypt(value, this.keys);
    const object = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    return object;
  }
}
