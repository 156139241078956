import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

import { SV_URL } from '../@core/utils/utils';

@Injectable()
export class RccProvider {

  public id: string;

  constructor(public http: HttpClient) {}

  saveRcc(data, cli, valor, status, tipo, user, alt, cod) {
    const url: string = SV_URL + '/postRcc';

    const postParams = {
      data: data,
      cli: cli ,
      valor: valor,
      status: status,
      tipo: tipo,
      user: user,
      alt: alt,
      cod: cod,
    };

    this.http.post(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

  putrcc(id, data, cli, valor, tipo, user, alt, cod) {
    const url: string = SV_URL + '/putRcc';

    const postParams = {
      id: id,
      data: data,
      cli: cli ,
      valor: valor,
      tipo: tipo,
      user: user,
      alt: alt,
      cod: cod,
    };

    this.http.put(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

  putrccstatus(id, cupom, status) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/putRccStatus';

      const postParams = {
        id: id,
        cupom: cupom,
        status: status,
      };

      this.http.put(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');
        resolve();
        }, error => {
        console.log(error);
        resolve();
      });
    });

  }

  putRccAutorizado(id, user, data, status) {
    const url: string = SV_URL + '/putRccAutorizado';

    const postParams = {
      id: id,
      user: user,
      data: data,
      status: status,
    };

    this.http.put(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

}
