import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

// import { createWorker } from 'tesseract.js';
import { DomSanitizer } from '@angular/platform-browser';
import { SendAppProvider } from '../../../../services/send';
import { SV_URL_AST } from '../../../../@core/utils/utils';
import { NgxUiLoaderService } from 'ngx-ui-loader';

// const PSM = require('tesseract.js/src/constants/PSM.js')


@Component({
  selector: 'ngx-dialog-template2',
  template: `
    <nb-card class='templatePhoto' [nbSpinner]="loading" nbSpinnerStatus="danger">
      <nb-card-header>
        <div>
            <button  class="btn btn-success btn-space" [disabled]="disabledAcation" (click)="confirma()">CONFIRMAR</button>
            <button  class="btn btn-danger btn-space" [disabled]="disabledAcation" (click)="repetir()">REPETIR</button>
            <button  class="btn btn-info btn-space" (click)="print()">Imprimir</button>
            <button  class="btn btn-warning btn-space" (click)="dismiss()">SAIR</button>
            <!-- <button  class="btn btn-warning btn-space" (click)="processa()">PROCESSA</button> -->
          <div>
          </div>
        </div>

      </nb-card-header>
    <nb-card-body>
      <div id="print-section">
        <p>CODIGO: {{cod}} - DATA: {{data}} HORA: {{hora}}</p>
        <div *ngIf="tipo === 'photo'">
          <img  class="image" id='imagemTeste' width="100%" [src]="img" *ngIf="img">
        </div>
        <div style="width: 800px; height: 90%" *ngIf="tipo === 'input'">
          <table class="result">
            <tr>
              <td class="title"><b>OBS</b></td>
              <td class="title"><b>JOGO</b></td>
              <td class="title"><b>VALOR</b></td>
            </tr>
            <tr *ngFor="let item of dados; index as position">
              <td>{{item.obs}}</td>
              <td>{{item.mil}}</td>
              <td>{{item.valor | currency:'BRL':true}}</td>
            </tr>
            <tr>
              <td></td>
              <td><b>SOMA</b></td>
              <td><b>{{soma | currency:'BRL':true}}</b></td>
            </tr>
          </table>

        </div>
      </div>

    </nb-card-body>

  </nb-card>

  `,
  styleUrls: ['./send-view.component.scss'],
})
export class DialogTemplate1Component implements OnInit, AfterViewInit, OnDestroy {
  @Input() cod: string;
  @Input() data: string;
  @Input() dataSist: string;
  @Input() asid: string;
  @Input() id: string;
  @Input() hora: string;
  @Input() tipo: string;
  @Input() serie: string;

  disabledAcation = false;

  imgConv;
  dados = [];
  loading = false;
  img;
  show = false;
  private soma = 0;

  constructor(
    protected ref: NbDialogRef<DialogTemplate1Component>,
    public domSanitizer: DomSanitizer,
    private sendProvider: SendAppProvider,
    public http: HttpClient,
    private loader: NgxUiLoaderService,
    private detc: ChangeDetectorRef,
  ) {

  }

  ngOnInit() {
    const dataTmp =  `${this.data.slice(6, 10)}-${this.data.slice(3, 5)}-${this.data.slice(0, 2)}`;
    if (dataTmp !== this.dataSist) {
      this.disabledAcation = true;
    }
    if (this.tipo === 'input') {
      this.getLista();
    } else {
      this.getImg();
    }
  }

  ngAfterViewInit() {
    if (!this.ref['destroyed']) {
      this.detc.detectChanges();
    }
  }


  ngOnDestroy() {
    this.detc.detach(); // do this
  }

  dismiss() {
    this.ref.close();
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
      <head>
          <style>
            body {
              width: 100%;
              height: 100px
            }
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`,
    );
    popupWin.document.close();
  }



  // processa() {

  //   const worker = createWorker({
  //     logger: m => console.log(m)
  //   });


  //   const teste = document.getElementById('imagemTeste') as HTMLImageElement;;
  //   this.imgConv = this.domSanitizer.bypassSecurityTrustResourceUrl(this.img);
  //   (async () => {
  //     await worker.load();
  //     await worker.loadLanguage('eng');
  //     await worker.initialize('eng');
  //     await worker.setParameters({
  //       tessedit_pageseg_mode: PSM.AUTO,
  //       tessedit_char_whitelist: '0123456789=,.'
  //     })
  //     const { data: { text } } = await worker.recognize(teste);
  //     console.log(text);
  //     await worker.terminate();
  //   })();
  // }


  confirma() {
    const user = atob(sessionStorage.getItem('uid'));
    this.sendProvider.putChangeCheck(this.id, 'confirmado', user, this.asid, this.cod, this.tipo, this.serie);
    this.dismiss();
  }

  repetir() {
    const user = atob(sessionStorage.getItem('uid'));
    this.sendProvider.putChangeCheck(this.id, 'repetir', user, this.asid, this.cod, this.tipo, this.serie);
    this.dismiss();
  }

  getLista() {
    this.http.get(`${SV_URL_AST}/getInputDataSerie/${this.serie}`).subscribe(dataDig => {
      let dadosInput;
      dadosInput = dataDig;
      if (dadosInput.length > 0) {
        this.dados = [];
        dadosInput.forEach(item => {
          this.dados.push({
            mil: item.mil,
            valor: item.valor,
            obs: item.obs.replace('&#x2F;', '/'),
          });
          this.soma += item.valor;
        });
        if (!this.ref['destroyed']) {
          this.detc.detectChanges();
        }
      }
    });
  }

  getImg() {
    this.loading = true;
    this.http.get(`${SV_URL_AST}/getImg/${this.asid.replace(/[#]/, '')}`).subscribe(dataDig => {
      let dadosInput;
      dadosInput = dataDig;

      if (dadosInput.length > 0) {
        this.img = this.domSanitizer.bypassSecurityTrustResourceUrl(dadosInput[0].img);
        this.loading = false;
        if (!this.ref['destroyed']) {
          this.detc.detectChanges();
        }
      } else {
        this.loading = false;
      }

    });
  }

  // async convertImg(img) {
  //   return new Promise(resolve => {
  //     let TYPED_ARRAY = new Uint8Array(img.data);
  //     const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
  //       return data + String.fromCharCode(byte);
  //     }, '');
  //     resolve(this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64, ' + STRING_CHAR))
  //   });
  // }

}
