import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { SV_URL } from '../@core/utils/utils';

@Injectable()
export class SaldosProvider {

  public id: string;
  constructor(public http: HttpClient) {
  }

  saveSaldo(data, cli, valor, user, alt) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/postSaldo';

      const postParams = {
        data: data,
        cli: cli ,
        valor: valor,
        alt_user: user,
        alt_data: alt,
      };

      this.http.post(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');
      }, error => {
        console.log(error);
      });
      resolve();
    });
  }

}

