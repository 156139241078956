import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NbDialogRef, NbToastrService } from "@nebular/theme";
import { ChatService } from "../../../services/chatService";
import { TarefasProvider } from "../../../services/tarefas";
import { CredentiaisService } from "../../../@core/utils/credentials.service";

@Component({
  selector: "ngx-dialog-tarefas",
  template: `
    <div class="dialog-tarefa">
      <nb-card [status]="status">
        <nb-card-header>Tarefa: {{ title }} | Solicitante {{item.solicitante | uppercase}}</nb-card-header>
        <nb-card-body>
        <div style="text-align: center;;"  id="tarefadiv"></div>
          <div class="col-sm-12 input-group"></div>
        </nb-card-body>
        <nb-card-footer>
          <div class="row">
            <div class="col-sm-">
              <button class="btn btn-success" (click)="conclusao()">Concluir</button>
            </div>
            <div class="col-sm-6 ">
              <button class="btn btn-danger" (click)="dismiss()">Sair</button>
            </div>
            <div>
              <b>Leitura: {{item.leitura.slice(0, 10) | date: 'dd/MM/yyyy' }} - {{item.leitura.slice(10, 20)}}</b>
            </div>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  `,
  styleUrls: ["./modalTarefas.component.scss"],
})
export class TarefaDialogComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() id: string;
  @Input() origem: string;
  @Input() item;



  status = 'success';

  msg: string = "";
  from: string = this.credentials.getUser();

  constructor(
    protected ref: NbDialogRef<TarefaDialogComponent>,
    private provider: TarefasProvider,
    public http: HttpClient,
    public router: Router,
    public chat: ChatService,
    private toastr: NbToastrService,
    private credentials: CredentiaisService
  ) { }

  ngOnInit(): void {
    this.provider.leitura(this.id);
    if (this.item.prioridade === 'URGENTE') {
      this.status = 'danger';
    }
    setTimeout(() => {
      this.ajustaMsg();
    }, 100);
  }

  dismiss() {
    this.ref.close();
  }

  conclusao() {
    this.provider.conclusao(this.id);
    this.toastr.success("Tarefa Concluída com Sucesso!");
    this.dismiss();
    if (this.origem === 'tarefas') {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

  ajustaMsg() {
    const content = document.getElementById('tarefadiv');
    if (this.text) {
      if (content) {
        const tamanho = content.childNodes;
        if (tamanho.length === 0) {
          const k = document.createElement('texto');
          k.innerHTML = this.text;
          content.appendChild(k);
        }
      }
    }
  }
}
