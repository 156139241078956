import { Injectable } from "@angular/core";

@Injectable()
export class DateUtilsProvider {
  constructor() {}

  dataHojeAnoMesDia() {
    const data = new Date();
    return data.toISOString().slice(0, 10);
  }

  formatDateDiaMesAno(data) {
    const dataFormat = data.slice(0, 10);
    return dataFormat.split("-").reverse().join("/");
  }

  convertDataDB(data) {
    return data.slice(6, 10) + "-" + data.slice(3, 5) + "-" + data.slice(0, 2);
  }
}
