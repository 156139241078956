import { Injectable } from '@angular/core';

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { EncrDecrService } from '../../@core/utils/encdec.service';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private EncrDecr: EncrDecrService, public router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: sessionStorage.getItem('x'),
      },
      body: { pld: this.EncrDecr.set(request.body) },
    });

    return next
      .handle(request)
      .map((event) => {
        if (event instanceof HttpResponse) {
          if (event.body.hasOwnProperty('pld')) {
            event = event.clone({
              body: JSON.parse(this.EncrDecr.get(event.body.pld)),
            });
          }
        }
        return event;
      })
      .catch((error: HttpErrorResponse) => {
        if (error && error.hasOwnProperty('status') && error.status === 556) {
          sessionStorage.removeItem('portal');
          sessionStorage.removeItem('uid');
          sessionStorage.removeItem('x');
          this.router.navigate(['/pages/authLogin/login']);
          window.location.reload();
        }
        return throwError(error);

      });
  }
}
