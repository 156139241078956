import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Home',
    icon: 'home',
    link: '/pages/dashboard',
    home: true,
    hidden: false,
  },
  {
    title: 'Vendas',
    icon: 'monitor-outline',
    link: '/pages/sales/home',
    hidden: false,
  },
  {
    title: 'Admin',
    icon: 'shield-outline',
    hidden: (sessionStorage.getItem('uid') !== 'YWRtaW4=' && sessionStorage.getItem('uid') !== 'cmU=') ,
    link: '/pages/master/home',
  },
  {
    title: 'Relatórios',
    icon: 'pie-chart-outline',
    link: '/pages/reports/home',
    hidden: false,
  },
  {
    title: 'Lançamentos',
    icon: 'monitor-outline',
    link: '/pages/sales/homelanc',
    hidden: false,
  },
  {
    title: 'Cambio',
    icon: 'monitor-outline',
    link: '/pages/cambio/home',
    hidden: false,
  },
];
