<nb-card class="container">
  <nb-card-body>
    <div id="print-section">
      <div class="header">
        <p>CLIENTE: <b>{{cod}}</b> - SETOR: <b>A - CASA</b> | DATA: <b>21/09/2021</b></p>
      </div>
      <br>
      <table class="tableItens">
        <tr class="trHeader">
          <td><b>LOTERIA</b></td>
          <td><b>FERIA</b></td>
          <td><b>PREMIO</b></td>
        </tr>
        <tr *ngIf="lote5['valor'] > 0">
          <td>COR</td>
          <td>{{ lote5['valor']| number : '1.2-2' }}</td>
          <td>
        <tr *ngFor="let item of lote5.premios">({{item.apos | number : '1.2-2'}}) {{item.valor | number : '1.2-2' }}
        </tr>
        </td>
        </tr>
        <tr>
          <td>{{lote1.desc}}</td>
          <td>{{ lote1['valor']| number : '1.2-2' }}</td>
          <td>
        <tr *ngFor="let item of lote1.premios">({{item.apos | number : '1.2-2'}}) {{item.valor | number : '1.2-2' }}
        </tr>
        </td>
        </tr>
        <tr>
          <td>{{lote2.desc}}</td>
          <td>{{ lote2['valor']| number : '1.2-2' }}</td>
          <td>
        <tr *ngFor="let item of lote2.premios">({{item.apos | number : '1.2-2'}}) {{item.valor | number : '1.2-2' }}
        </tr>
        </td>
        </tr>
        <tr>
          <td>{{lote3.desc}}</td>
          <td>{{ lote3['valor']| number : '1.2-2' }}</td>
          <td>
        <tr *ngFor="let item of lote3.premios">({{item.apos | number : '1.2-2'}}) {{item.valor | number : '1.2-2' }}
        </tr>
        </td>
        </tr>
        <tr>
          <td>{{lote4.desc}}</td>
          <td>{{ lote4['valor']| number : '1.2-2' }}</td>
          <td>
        <tr *ngFor="let item of lote4.premios">({{item.apos | number : '1.2-2'}}) {{item.valor | number : '1.2-2' }}
        </tr>
        </td>
        </tr>

        <!-- <tr>
            <td>{{lote6.desc}}</td>
            <td>{{ lote6['valor']| number : '1.2-2' }}</td>
            <td>
              <tr *ngFor="let item of lote6.premios">({{item.apos}}) {{item.valor | number : '1.2-2' }}</tr>
            </td>
          </tr> -->


      </table>
      <br>
      <table class="tablePai">
        <tr>
          <td class="tdResult">
            <table class="tableResult">
              <tr>
                <TD class="td1">TOTAL PECAS</TD>
                <TD>{{ resumo?.pecas | number : '1.2-2' }}</TD>
              </tr>
              <tr>
                <TD class="td1">COMISSAO</TD>
                <TD>{{ resumo?.comissao | number : '1.2-2' }}</TD>
              </tr>
              <tr>
                <TD class="td1">{{resumo?.lucro >= 0 ? 'LUCRO' : 'PREJUIZO'}}</TD>
                <TD>{{ resumo?.lucro | number : '1.2-2' }}</TD>
              </tr>
              <tr>
                <TD class="td1">PREMIOS</TD>
                <TD>{{ resumo?.premios | number : '1.2-2' }}</TD>
              </tr>
              <tr *ngIf="resumo?.deu">
                <TD class="td1">DEU</TD>
                <TD>{{ resumo?.deu | number : '1.2-2' }}</TD>
              </tr>
              <tr *ngIf="resumo?.envio">
                <TD class="td1">ENVIO</TD>
                <TD>{{ resumo?.envio | number : '1.2-2' }}</TD>
              </tr>
              <tr *ngIf="resumo?.anterior >= 0">
                <TD class="td1">DV ANTERIOR</TD>
                <TD>{{ resumo?.anterior | number : '1.2-2' }}</TD>
              </tr>
              <tr *ngIf="resumo?.anterior < 0">
                <TD class="td1">HV ANTERIOR</TD>
                <TD>{{ resumo?.anterior | number : '1.2-2' }}</TD>
              </tr>
              <tr *ngIf="resumo?.final >= 0">
                <TD class="td1">DV ATUAL</TD>
                <TD>{{ resumo?.final | number : '1.2-2' }}</TD>
              </tr>
              <tr *ngIf="resumo?.final < 0">
                <TD class="td1">HV ATUAL</TD>
                <TD>{{ resumo?.final | number : '1.2-2' }}</TD>
              </tr>
            </table>
          </td>
          <td class="obs">
          </td>
        </tr>
      </table>

    </div>
  </nb-card-body>
  <nb-card-footer>
    <!-- <div class="col-sm-4 form-group">
      <button class="btn btn-danger" (click)="finalizar()">Finalizar</button>
    </div> -->
  </nb-card-footer>
</nb-card>
