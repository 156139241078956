import { SV_URL_AST } from './../../../../@core/utils/utils';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { UtilsProvider } from '../../../../services/utils';


@Injectable()
export class ResultBasicrovider {

  public id: string;

  constructor(public utils: UtilsProvider) { }

  saveResult(lt, pc, value, user) {
    const url: string = SV_URL_AST + '/saveResultPc';

    const postParams = {
      pc: pc,
      value: value,
      lt: lt,
      user: user,
    };

    return this.utils.postHttpClient(url, postParams);

  }

  saveResultMaster(date, lt, pc, value, status) {
    const url: string = SV_URL_AST + '/saveResultMaster';

    const postParams = {
      date: date,
      pc: pc,
      value: value,
      lt: lt,
      status: status,
    };

    return this.utils.postHttpClient(url, postParams);
  }


  getResult(data) {
    const url: string = SV_URL_AST + '/getResult';

    const postParams = {
      data: data,
    };

    return this.utils.getHttpClient(url, postParams);

  }

  confirmar(lt) {
    const url: string = SV_URL_AST + '/confirmarResult';

    const postParams = {
      lt: lt,
    };

    return this.utils.post(url, postParams);

  }

  getHorario() {
    const url: string = SV_URL_AST + '/getHorario';

    const postParams = {
    };

    return this.utils.getHttpClient(url, postParams);

  }

  editResult(id, data, lt, seq, value, status) {
    const url: string = SV_URL_AST + '/editResult';

    const postParams = {
      id: id,
      data: data,
      loteria: lt,
      seq: seq,
      value: value,
      status: status,
    };

    return this.utils.postHttpClient(url, postParams);

  }



}

