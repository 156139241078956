import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

import { SV_URL } from '../@core/utils/utils';

@Injectable()
export class PremiosProvider {
  public id: string;
  constructor(public http: HttpClient) {}


  savePremio1(data, cli, valor, seq, user, alt) {
    const url: string = SV_URL + '/postPremio1';

    const postParams = {
      data: data,
      cli: cli ,
      valor: valor,
      status: status,
      seq: seq,
      user: user,
      alt: alt,
    };

    return this.http.post(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

  savePremioRet(data, cli, valor, seq, user, alt) {
    const url: string = SV_URL + '/postPremio1';

    const postParams = {
      data: data,
      cli: cli ,
      valor: valor,
      status: status,
      seq: seq,
      user: user,
      alt: alt,
    };

    return this.http.post(url, postParams);

  }

  putPremio1(id, data, cli, valor, seq, user, alt) {
    const url: string = SV_URL + '/putPremio1';

    const postParams = {
      id: id,
      data: data,
      cli: cli ,
      valor: valor,
      seq: seq,
      user: user,
      alt: alt,
    };

    this.http.put(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

  putPremio1Status(id, cupom, status) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/putPremio1Status';

      const postParams = {
        id: id,
        cupom: cupom,
        status: status,
      };

      this.http.put(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');
        resolve();
        }, error => {
        console.log(error);
        resolve();
      });
    });
  }

  putPremio1Aut(id, user, data) {
    const url: string = SV_URL + '/putPremio1Aut';

    const postParams = {
      id: id,
      user: user,
      data: data,
    };

    this.http.put(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }
}

