import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { SV_URL } from '../../../../@core/utils/utils';


@Injectable()
export class MapaLoteriasMilharService {

  public id: string;

  constructor(public http: HttpClient) {}

  save(data, tipo, seq, valor, user, alt, loteria) {
    return new Promise((resolve, reject) => {
      const url: string = SV_URL + '/ml/postMapa';

      const postParams = {
        data: data,
        tipo: tipo ,
        seq: seq,
        valor: valor,
        user: user,
        alt: alt,
        loteria: loteria
      };

      this.http.post(url, postParams)
      .subscribe(res => {
        resolve(res);
        console.log('Enviado Com Sucesso');
        }, error => {
        console.log(error);
        reject();
      });
    });
  }

  finalizar(id, folha) {
    return new Promise((resolve, reject) => {
      const url: string = SV_URL + '/ml/putMapaFinal';

      const postParams = {
        id: id,
        folha: folha,
      };

      this.http.put(url, postParams)
      .subscribe(res => {
        resolve(res);
        console.log('Enviado Com Sucesso');
        }, error => {
        console.log(error);
        reject();
      });
    });
  }

  finalizarNovo(lista, folha) {
    return new Promise((resolve, reject) => {
      const url: string = SV_URL + '/ml/putMapaFinalNovo';

      const postParams = {
        lista: lista,
        folha: folha
      };

      this.http.put(url, postParams)
      .subscribe(res => {
        resolve(res);
        console.log('Enviado Com Sucesso');
        }, error => {
        console.log(error);
        reject();
      });
    });
  }

  saveAnalise(data, folha, hora, valor, tempo, quantidade, loteria, tipo) {
    return new Promise((resolve, reject) => {
      const url: string = SV_URL + '/ml/postMapaAnalise';

      const postParams = {
        data: data,
        folha: folha ,
        hora: hora,
        valor: valor,
        tempo: tempo,
        quantidade: quantidade,
        loteria: loteria,
        tipo: tipo
      };

      this.http.post(url, postParams)
      .subscribe(res => {
        resolve(res);
        console.log('Enviado Com Sucesso');
        }, error => {
        console.log(error);
        reject();
      });
    });
  }

}
