<div class="header-container">
  <div class="logo-container">
    <a class="logo" href="#" (click)="navigateHome()"><span>RELUZ {{ambiente}}</span></a>
  </div>
  <div class="tarefa-container" *ngIf="tarefasPendentes" (click)="navegar('/pages/tarefas/tarefa')">
    TAREFAS PENDENTES
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action *ngIf="astral" (click)="navegar('/pages/astral/home')" class="menu-nav">ASTRAL</nb-action>
    <nb-action *ngIf="lanc" (click)="navegar('/pages/sales/homelanc')" class="menu-nav">LANÇAMENTOS</nb-action>
    <nb-action *ngIf="vend" (click)="navegar('/pages/sales/home')" class="menu-nav">VENDAS</nb-action>
    <nb-action *ngIf="rela" (click)="navegar('/pages/reports/home')" class="menu-nav">RELATÓRIOS</nb-action>
    <nb-action *ngIf="gest" (click)="navegar('/pages/master/home')" class="menu-nav">GESTÃO</nb-action>
    <nb-action *ngIf="camb" (click)="navegar('/pages/cambio/home')" class="menu-nav">CÂMBIO</nb-action>



    <nb-action class="control-item newMSg" icon="email" [badgeText]="novaMsg" badgeStatus="danger" (click)="goToMsg()">
    </nb-action>
    <nb-action class="control-item newMSg" icon="clipboard-outline" (click)="navegar('/pages/tarefas/tarefa')">
    </nb-action>
    <nb-action>
      <nb-user [name]="userName"></nb-user>
    </nb-action>
    <nb-action icon="close" class="close" (click)="logOut()"></nb-action>
  </nb-actions>
</div>
