import { Component } from "@angular/core";

@Component({
  selector: "ngx-one-column-layout",
  styleUrls: ["./one-column.layout.scss"],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed class="custom" *ngIf="logado">
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-layout-column [ngClass]="{ fundo: !logado }">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  logado: boolean = sessionStorage.getItem("uid") ? true : false;
}
