import { HttpClient } from "@angular/common/http";

import { Component, OnInit } from "@angular/core";
import { SimpleTimer } from "ng2-simple-timer";
import { LocalDataSource } from "ng2-smart-table";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { MENU_ITEMS } from "./pages/pages-menu";
import { SV_URL } from "./@core/utils/utils";
import { UtilsProvider } from "./services/utils";
import { BnNgIdleService } from "bn-ng-idle";
import { ModalSessaoExpiradaComponent } from "./@theme/modals/sessaoExpiradaModal.component";
import { CredentiaisService } from "./@core/utils/credentials.service";

@Component({
  selector: "ngx-app",
  template: "<router-outlet></router-outlet><ngx-ui-loader></ngx-ui-loader>",
})
export class AppComponent implements OnInit {
  counter0 = 0;
  timer0Id: string;
  seq0: string;
  sourcePecas: LocalDataSource = new LocalDataSource();

  counter1 = 0;
  timer1Id: string;
  seq1: string;
  sourcePre: LocalDataSource = new LocalDataSource();

  counter2 = 0;
  timer2Id: string;
  seq2: string;
  sourceDesc: LocalDataSource = new LocalDataSource();

  dia = new Date();

  menu = MENU_ITEMS;

  menuVendas: Array<any> = [];
  menuLancamentos: Array<any> = [];
  menuRelatorios: Array<any> = [];
  menuAdmin: Array<any> = [];
  menuCambio: Array<any> = [];
  menuAstral: Array<any> = [];

  constructor(
    private st: SimpleTimer,
    private toastr: NbToastrService,
    public http: HttpClient,
    private bnIdle: BnNgIdleService,
    private dialogService: NbDialogService,
    public utils: UtilsProvider,
    private credentials: CredentiaisService
  ) {}

  async ngOnInit(): Promise<void> {
    this.st.newTimer("1sec", 1, true);
    this.timerSessao();
    await this.carregarMenus();
  }

  timerSessao() {
    sessionStorage.removeItem("actsit");
    if (sessionStorage.getItem("portal")) {
      console.log("timer iniciado");
      this.bnIdle.startWatching(3600).subscribe((isTimedOut: boolean) => {
        const act = sessionStorage.getItem("actsit");
        if (isTimedOut && act !== "005") {
          sessionStorage.setItem("actsit", "005"); // timeout
          this.bnIdle.stopTimer();
          this.bnIdle.resetTimer();
          this.modalSessaoExpirada();
        }
      });
    }
  }

  subscribeTimerPecas(seq) {
    this.seq0 = seq;
    if (seq) {
      if (this.timer0Id) {
        this.st.unsubscribe(this.timer0Id);
        this.timer0Id = undefined;
      } else {
        this.timer0Id = this.st.subscribe("1sec", () =>
          this.timer0callback(seq)
        );
      }
    } else {
      this.toastr.danger("Selecione a Peça", "Atenção");
    }
  }

  timer0callback(seq): void {
    let time = 2700;

    if (this.dia.getDay() === 6 || this.dia.getDay() === 3) {
      if (seq === "4") {
        time = 5400;
      }
    }

    if (this.counter0 === time) {
      this.st.unsubscribe(this.timer0Id);
      this.counter0 = 0;
      this.seq0 = "";
      this.sourcePecas = new LocalDataSource();
    } else {
      this.counter0++;
    }
  }

  subscribeTimerPre(seq) {
    this.seq1 = seq;
    if (seq) {
      if (this.timer1Id) {
        this.st.unsubscribe(this.timer1Id);
        this.timer1Id = undefined;
      } else {
        this.timer1Id = this.st.subscribe("1sec", () =>
          this.timer1callback(seq)
        );
      }
    } else {
      this.toastr.danger("Selecione a Peça", "Atenção");
    }
  }

  timer1callback(seq): void {
    let time = 2700;

    if (this.dia.getDay() === 6 || this.dia.getDay() === 3) {
      if (seq === "4") {
        time = 5400; // 1:30h
      }
    }

    if (this.counter1 === time) {
      this.st.unsubscribe(this.timer1Id);
      this.counter1 = 0;
      this.seq1 = "";
      this.sourcePre = new LocalDataSource();
    } else {
      this.counter1++;
    }
  }

  subscribeTimerDesc(seq) {
    this.seq2 = seq;
    if (seq) {
      if (this.timer2Id) {
        this.st.unsubscribe(this.timer2Id);
        this.timer2Id = undefined;
      } else {
        this.timer2Id = this.st.subscribe("1sec", () =>
          this.timer2callback(seq)
        );
      }
    } else {
      this.toastr.danger("Selecione a Peça", "Atenção");
    }
  }

  timer2callback(seq): void {
    let time = 2700;

    if (this.dia.getDay() === 6 || this.dia.getDay() === 3) {
      if (seq === "4") {
        time = 5400;
      }
    }

    if (this.counter2 === time) {
      this.st.unsubscribe(this.timer2Id);
      this.counter2 = 0;
      this.seq2 = "";
      this.sourceDesc = new LocalDataSource();
    } else {
      this.counter2++;
    }
  }

  carregarMenus() {
    return new Promise<void>((resolve) => {
      if (sessionStorage.getItem("uid")) {
        this.menu[1].hidden = true;
        this.menu[3].hidden = true;
        this.menu[4].hidden = true;

        const data = {
          user: this.credentials.getUser(),
        };

        this.utils
          .post(SV_URL + "/getUserRole", {
            pld: btoa(JSON.stringify(data)),
          })
          .subscribe(async (dataRet: string) => {
            if (dataRet) {
              await this.carregaVendas(dataRet);
              await this.carregaLancamentos(dataRet);
              await this.carregaRelatorios(dataRet);
              await this.carregaCambio(dataRet);
              await this.carregaAstral(dataRet);
              resolve();
            }
          });
      }
    });
  }

  carregaVendas(data) {
    return new Promise<void>((resolve) => {
      this.menuVendas = [];

      if (data[0].cheque) {
        this.menuVendas.push({
          title: "Cheque",
          icon: "credit-card-outline",
          link: "/pages/sales/cheque",
        });
      }

      if (data[0].deu) {
        this.menuVendas.push({
          title: "Deu",
          icon: "download-outline",
          link: "/pages/sales/deu-sales",
        });
      }

      if (data[0].descarga_geral) {
        this.menuVendas.push({
          title: "Descarga",
          icon: "arrowhead-right-outline",
          link: "/pages/sales/descarga",
        });
      }

      if (data[0].envio) {
        this.menuVendas.push({
          title: "Envio",
          icon: "arrow-circle-right-outline",
          link: "/pages/sales/envio",
        });
      }

      if (data[0].folgas) {
        this.menuVendas.push({
          title: "Folgas",
          icon: "calendar-outline",
          link: "/pages/sales/folgas",
        });
      }

      if (data[0].pecas_geral) {
        this.menuVendas.push({
          title: "Peças",
          icon: "edit-2-outline",
          link: "/pages/sales/new-sales",
        });
      }

      if (data[0].premios_geral) {
        this.menuVendas.push({
          title: "Prêmios",
          icon: "award-outline",
          link: "/pages/sales/premios",
        });
      }

      if (data[0].rcc) {
        this.menuVendas.push({
          title: "RCC",
          icon: "message-circle-outline",
          link: "/pages/sales/rcc",
        });
      }

      if (data[0].vendedores) {
        this.menuVendas.push({
          title: "Vendedores",
          icon: "people-outline",
          link: "/pages/sellers/new-seller",
        });
      }

      if (data[0].coringa) {
        this.menuVendas.push({
          title: "Coringa",
          icon: "bulb-outline",
          link: "/pages/sales/coringa",
        });
      }

      if (data[0].despesas) {
        this.menuVendas.push({
          title: "Despesas",
          icon: "swap-outline",
          link: "/pages/sales/despesas",
        });
      }

      if (data[0].folha) {
        this.menuVendas.push({
          title: "Folha",
          icon: "file-text-outline",
          link: "/pages/sales/folha",
        });
      }

      if (this.menuVendas.length > 0) {
        this.menu[1].hidden = false;
      }
      resolve();
    });
  }

  carregaRelatorios(data) {
    // RELATORIOS
    return new Promise<void>((resolve) => {
      this.menuRelatorios = [];
      if (data[0].cheque) {
        this.menuRelatorios.push({
          title: "Cheques",
          icon: "credit-card-outline",
          link: "/pages/reports/cheque-report",
        });
      }

      if (data[0].cheque) {
        this.menuRelatorios.push({
          title: "Coringa",
          icon: "bulb-outline",
          link: "/pages/reports/coringa-report",
        });
      }

      if (data[0].deu) {
        this.menuRelatorios.push({
          title: "Deu",
          icon: "download-outline",
          link: "/pages/reports/deu-report",
        });
      }

      if (data[0].rela_premios) {
        this.menuRelatorios.push({
          title: "Descarga",
          icon: "arrowhead-right-outline",
          link: "/pages/reports/descarga-report",
        });
      }

      if (data[0].despesas) {
        this.menuRelatorios.push({
          title: "Despesas",
          icon: "swap-outline",
          link: "/pages/reports/despesas-report",
        });
      }

      if (data[0].envio) {
        this.menuRelatorios.push({
          title: "Envio",
          icon: "arrow-circle-right-outline",
          link: "/pages/reports/envio-report",
        });
      }

      if (data[0].rela_planilha_lot) {
        this.menuRelatorios.push({
          title: "Planilha Lot",
          icon: "layout-outline",
          link: "/pages/reports/loteReport",
        });
      }

      if (data[0].rela_premios) {
        this.menuRelatorios.push({
          title: "Prêmios",
          icon: "award-outline",
          link: "/pages/reports/premios-report",
        });
      }

      if (data[0].rcc) {
        this.menuRelatorios.push({
          title: "RCC",
          icon: "message-circle-outline",
          link: "/pages/reports/rcc-report",
        });
      }

      if (data[0].rela_vendedores) {
        this.menuRelatorios.push({
          title: "Vendedores",
          icon: "people-outline",
          link: "/pages/reports/report",
        });
      }

      if (data[0].rela_vendedores) {
        this.menuRelatorios.push({
          title: "Conferência",
          icon: "people-outline",
          link: "/pages/reports/conferencia-report",
        });
      }

      if (data[0].rela_premios_data) {
        this.menuRelatorios.push({
          title: "Prêmio Datas",
          icon: "award-outline",
          link: "/pages/reports/premios-range-report",
        });
      }

      if (this.menuRelatorios.length > 0) {
        this.menu[3].hidden = false;
      }
      resolve();
    });
  }

  carregaLancamentos(data) {
    return new Promise<void>((resolve) => {
      this.menuLancamentos = [];

      if (data[0].pecas_lancamento) {
        this.menuLancamentos.push({
          title: "Peças",
          icon: "plus-square-outline",
          link: "/pages/sales/new-sales-basic",
        });
      }

      if (data[0].premios_lancamento) {
        this.menuLancamentos.push({
          title: "Prêmios",
          icon: "plus-circle-outline",
          link: "/pages/sales/premios-basic",
        });
      }

      if (data[0].descarga_lancamento) {
        this.menuLancamentos.push({
          title: "Descarga",
          icon: "arrowhead-right-outline",
          link: "/pages/sales/descarga-basic",
        });
      }


      if (data[0].mapa) {
        this.menuLancamentos.push({
          title: "Mapa Milhar",
          icon: "map",
          link: "/pages/sales/mapa_milhar",
        });
      }

      if (data[0].mapa) {
        this.menuLancamentos.push({
          title: "Mapa Centena",
          icon: "map",
          link: "/pages/sales/mapa_centena",
        });
      }

      if (data[0].resultados) {
        this.menuLancamentos.push({
          title: "Resultados",
          icon: "alert-circle-outline",
          link: "/pages/sales/resultBasic",
        });
      }

      if (this.menuLancamentos.length > 0) {
        this.menu[4].hidden = false;
      }
      resolve();
    });
  }

  carregaCambio(data) {
    return new Promise<void>((resolve) => {
      this.menuCambio = [];

      if (data[0].cambio) {
        this.menuCambio.push({
          title: "Vendedores",
          icon: "people-outline",
          link: "/pages/cambio/vendedores",
        });
        this.menuCambio.push({
          title: "Férias",
          icon: "edit-2-outline",
          link: "/pages/cambio/ferias",
        });
        this.menuCambio.push({
          title: "Prêmios",
          icon: "award-outline",
          link: "/pages/cambio/premios",
        });
        this.menuCambio.push({
          title: "Deu",
          icon: "download-outline",
          link: "/pages/cambio/deu",
        });
        this.menuCambio.push({
          title: "Envios",
          icon: "arrow-circle-right-outline",
          link: "/pages/cambio/envios",
        });
        this.menuCambio.push({
          title: "Movimento",
          icon: "file-text-outline",
          link: "/pages/cambio/extrato-diario",
        });
        this.menuCambio.push({
          title: "Relatório Férias",
          icon: "file-text-outline",
          link: "/pages/cambio/rela-ferias",
        });
      }

      if (this.menuCambio.length > 0) {
        this.menu[3].hidden = false;
      }

      resolve();
    });
  }

  carregaAstral(data) {
    return new Promise<void>((resolve) => {
      this.menuAstral = [];

      if (data[0].fotos) {
        this.menuAstral.push({
          title: "Fotos",
          icon: "file-text-outline",
          link: "/pages/astral/send",
        });
      }
      if (data[0].astuser) {
        this.menuAstral.push({
          title: "Usuários",
          icon: "people-outline",
          link: "/pages/astral/user",
        });
      }

      if (data[0].astuser) {
        this.menuAstral.push({
          title: "Categorias",
          icon: "people-outline",
          link: "/pages/astral/categorias",
        });
      }
      // this.menuAstral.push({
      //   title: 'Relatorio B',
      //   icon: 'plus-square-outline',
      //   link: '/pages/cambio/extrato-diario'
      // })
      // }

      if (this.menuAstral.length > 0) {
        this.menu[4].hidden = false;
      }

      resolve();
    });
  }

  modalSessaoExpirada() {
    this.dialogService.open(ModalSessaoExpiradaComponent);
  }
}
