import { AuthGuardService } from "./services/auth-guard";
import { CheckCircleComponent } from "./@core/utils/visual.component";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpModule } from "@angular/http";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { CoreModule } from "./@core/core.module";
import { ThemeModule } from "./@theme/theme.module";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import {
  NbAlertModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbToastrModule,
  NbWindowModule,
} from "@nebular/theme";
import { ServicesProvider } from "./services/services";
import { SalesSimpliProvider } from "./services/salesSimpli";
import { DeuProvider } from "./services/deu";
import { DescargaProvider } from "./services/descarga";
import { ChequeProvider } from "./services/cheque";
import { EnvioProvider } from "./services/envio";
import { LoginProvider } from "./services/login";
import { FolhaProvider } from "./services/folha";
import { PremiosProvider } from "./services/premios";
import { RccProvider } from "./services/rcc";
import { CoringaProvider } from "./services/coringa";
import { ComunicadoProvider } from "./services/comunicado";
import { FolgaProvider } from "./services/folga";
import { SaldosProvider } from "./services/saldos";
import { DespesasProvider } from "./services/despesas";
import { ChatService } from "./services/chatService";
import { UserRoleProvider } from "./services/userRole";
import { WebsocketService } from "./services/webSocketService";
import { UsersProvider } from "./services/users";
import { UtilsProvider } from "./services/utils";
import { SendAppProvider } from "./services/send";
import { DateUtilsProvider } from "./@core/utils/date.utils";
import { AstCategoriasProvider } from "./services/astCategorias";
import { CambioProvider } from "./services/cambio";

import { SimpleTimer } from "ng2-simple-timer";
import { DialogTemplateComponent } from "./pages/dashboard/card-tarefas/templateModal.component";
import { FormsModule } from "@angular/forms";
import { InterceptorModule } from "./pages/authLogin/token.module";

import { Ng2SmartTableModule } from "ng2-smart-table";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { DialogTemplate1Component } from "./pages/astral/send/send-view/templateModal.component";
import { RuaTemplateComponent } from "./pages/cambio/extrato-diario/modal/ruaModal/ruaModal.component";
import { ResultBasicrovider } from "./pages/sales/lancamentos/resultados-basic/result-basic.service";
import { AuthService } from "./services/auth";
import { JwtHelperService, JWT_OPTIONS } from "@auth0/angular-jwt";
import { FormatUtilsProvider } from "./@core/utils/format.utils";
import { BnNgIdleService } from "bn-ng-idle";
import { TarefasProvider } from "./services/tarefas";
import { TarefaDialogComponent } from "./pages/components/modalTarefas/modalTarefas.component";
import { MapaLoteriasMilharService } from "./pages/sales/lancamentos/mapa_milhar/mapa_milhar.service";
import { MapaLoteriasCentenaService } from "./pages/sales/lancamentos/mapa_centena/mapa_centena.service";

@NgModule({
  declarations: [
    AppComponent,
    CheckCircleComponent,
    DialogTemplateComponent,
    DialogTemplate1Component,
    RuaTemplateComponent,
    TarefaDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    HttpModule,
    ThemeModule.forRoot(),
    NbCardModule,
    NbSpinnerModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDialogModule.forRoot(),
    NbAlertModule,
    CoreModule.forRoot(),
    NgxUiLoaderModule,
    InterceptorModule,
    Ng2SmartTableModule,
    NbCheckboxModule,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogTemplateComponent,
    DialogTemplate1Component,
    RuaTemplateComponent,
  ],
  providers: [
    ServicesProvider,
    SalesSimpliProvider,
    DeuProvider,
    DescargaProvider,
    ChequeProvider,
    EnvioProvider,
    LoginProvider,
    PremiosProvider,
    FolhaProvider,
    RccProvider,
    CoringaProvider,
    ComunicadoProvider,
    TarefasProvider,
    FolgaProvider,
    SaldosProvider,
    DespesasProvider,
    ChatService,
    UserRoleProvider,
    WebsocketService,
    UsersProvider,
    SimpleTimer,
    UtilsProvider,
    MapaLoteriasMilharService,
    MapaLoteriasCentenaService,
    SendAppProvider,
    DateUtilsProvider,
    FormatUtilsProvider,
    ResultBasicrovider,
    AstCategoriasProvider,
    CambioProvider,
    AuthGuardService,
    AuthService,
    BnNgIdleService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
  ],
})
export class AppModule {}
