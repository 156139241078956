import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { AuthService } from "./auth";
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(): boolean {
    const logado = sessionStorage.getItem("portal") !== null;
    if (!this.auth.isAuthenticated()) {
      if (logado) {
        sessionStorage.removeItem("portal");
        sessionStorage.removeItem("uid");
        sessionStorage.removeItem("x");
        this.router.navigate(["/pages/authLogin/login"]);
        window.location.reload();
        return false;
      }
    }
    return true;
  }
}
