import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";

import { SV_URL } from "../@core/utils/utils";

@Injectable()
export class DescargaProvider {
  public id: string;

  constructor(public http: HttpClient) {}

  saveDescarga(data, cod, seq, valor, user, alt) {
    const url: string = SV_URL + "/postDescarga";

    const postParams = {
      data: data,
      cod: cod,
      valor: valor,
      seq: seq,
      user: user,
      alt: alt,
    };

    return this.http.post(url, postParams).subscribe(
      (res) => {
        console.log("Enviado Com Sucesso");
      },
      (error) => {
        console.log(error);
      }
    );
  }

  saveDescargaRet(data, cod, seq, valor, user, alt) {
    const url: string = SV_URL + "/postDescarga";

    const postParams = {
      data: data,
      cod: cod,
      valor: valor,
      seq: seq,
      user: user,
      alt: alt,
    };

    return this.http.post(url, postParams);
  }

  putDescarga(id, cod, data, seq, valor, user, alt) {
    const url: string = SV_URL + "/putDescarga";

    const postParams = {
      id: id,
      cod: cod,
      data: data,
      valor: valor,
      seq: seq,
      user: user,
      alt: alt,
    };

    this.http.put(url, postParams).subscribe(
      (res) => {
        console.log("Enviado Com Sucesso");
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
