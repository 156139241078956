import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { reject } from 'q';
import { SV_URL } from '../@core/utils/utils';
import { UtilsProvider } from './utils';

@Injectable()
export class UsersProvider {

  public id: string;
  constructor(
    public http: HttpClient,
    public utils: UtilsProvider) {
  }

  create(user, pwd, tipo, data, userCreate) {
    return new Promise(resolve => {
      const url: string = SV_URL + '/createUser';

      const postParams = {
        user, pwd, tipo, data, userCreate,
      };

      this.http.post(url, postParams)
      .subscribe({
        next: (res) => {
          resolve(res['insertId']);
        },
        error: () => {
          reject();
        },
      });
    });
  }

  edit(id, user, pwd, tipo, data, userCreate) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/editUser';

      const postParams = {
        id, user, pwd, tipo, data, userCreate,
      };

      this.http.put(url, postParams)
      .subscribe({
        next: () => {
          resolve();
        },
        error: () => {
          reject();
        },
      });
    });
  }

  updateStatus(id, status) {
    return new Promise<void>((resolve) => {
      const url: string = SV_URL + "/updateBlockStatus";

      const postParams = {
        id,
        status,
      };

      this.http.put(url, postParams).subscribe(
        (res) => {
          console.log("Enviado Com Sucesso");
        },
        (error) => {
          console.log(error);
        }
      );
      resolve();
    });
  }
}

