import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import "rxjs/add/operator/map";

import { SV_URL_AST } from "../@core/utils/utils";

@Injectable()
export class AstCategoriasProvider {
  public id: string;

  constructor(public http: HttpClient) {}

  save(desc, sub) {
    const url: string = SV_URL_AST + "/createCategoria";

    const postParams = {
      desc,
      sub,
    };

    this.http.post(url, postParams).subscribe(
      (res) => {
        console.log("Enviado Com Sucesso");
      },
      (error) => {
        console.log(error);
      }
    );
  }

  put(id, desc, sub) {
    const url: string = SV_URL_AST + "/putCategoria";

    const postParams = {
      id,
      desc,
      sub,
    };

    this.http.put(url, postParams).subscribe(
      (res) => {
        console.log("Enviado Com Sucesso");
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
