import { Component, OnDestroy, OnInit } from "@angular/core";
import { NbMenuService } from "@nebular/theme";

import { Subject } from "rxjs";
import { ChatService } from "../../../services/chatService";
import { AppComponent } from "../../../app.component";
import { Router } from "@angular/router";
import { SV_URL } from "../../../@core/utils/utils";
import { HttpClient } from "@angular/common/http";
import { CredentiaisService } from "../../../@core/utils/credentials.service";
import { WebsocketService } from "../../../services/webSocketService";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  userMenu = [{ title: "Profile" }, { title: "Log out" }];
  userName: string = this.credentials.getUser();

  currentTheme = "default";

  newMsg: boolean = false;
  novaMsg = "";
  astral: boolean = false;
  lanc: boolean = false;
  vend: boolean = false;
  rela: boolean = false;
  gest: boolean = false;
  camb: boolean = false;

  ambiente = SV_URL;
  tarefasPendentes = false;
  tasks: Subject<any>;

  constructor(
    private menuService: NbMenuService,
    public router: Router,
    public chat: ChatService,
    public home: AppComponent,
    public http: HttpClient,
    private credentials: CredentiaisService,
    private wsService: WebsocketService,
  ) {

    this.tasks = <Subject<any>>wsService
      .connect()
      .map((response: any): any => {
        return response;
      });
  }

  async ngOnInit() {
    if (this.ambiente.indexOf("localhost") > 0) {
      this.ambiente = "LOCAL";
    } else {
      this.ambiente = "";
    }

    this.chat.messages.subscribe((msg) => {
      if (msg["to"] === this.credentials.getUser()) {
        this.newMsg = true;
        this.novaMsg = "Nova";
      }
    });

    this.tasks.subscribe(async msg => {
      console.log(msg)
      this.buscarTarefas()
    });

    await this.home.carregarMenus();
    this.buscarTarefas()

    this.lanc = this.home.menuLancamentos.length > 0 ? true : false;
    this.vend = this.home.menuVendas.length > 0 ? true : false;
    this.rela = this.home.menuRelatorios.length > 0 ? true : false;
    this.gest = !(
      sessionStorage.getItem("uid") !== "YWRtaW4=" &&
      sessionStorage.getItem("uid") !== "cmU="
    );
    this.camb = this.home.menuCambio.length > 0 ? true : false;
    this.astral = this.home.menuAstral.length > 0 ? true : false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  goToMsg() {
    this.router.navigate(["/pages/messages/msg"]);
  }

  navegar(rota) {
    this.router.navigate([rota]);
  }

  logOut() {
    sessionStorage.removeItem("portal");
    sessionStorage.removeItem("uid");
    sessionStorage.removeItem("x");
    window.location.reload();
  }

  buscarTarefas() {
    return new Promise<void>((resolve) => {
      this.http
        .get(SV_URL + "/getTarefasPendentes/" + this.credentials.getUser())
        .subscribe((data) => {
          let tarefas;
          tarefas = data;
          if (tarefas && tarefas.length > 0) {
            this.tarefasPendentes = true;
          } else {
            this.tarefasPendentes = false;
          }
          resolve();
        });
    });
  }
}
