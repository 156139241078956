import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";

import { SV_URL } from "../@core/utils/utils";
import { Router } from "@angular/router";
import { UtilsProvider } from "./utils";
import { CredentiaisService } from "../@core/utils/credentials.service";

@Injectable()
export class UserRoleProvider {
  public id: string;
  constructor(
    public http: HttpClient,
    public router: Router,
    public utils: UtilsProvider,
    private credentials: CredentiaisService
  ) {}

  getUserRole(user, rotina) {
    return new Promise((resolve) => {
      const url: string = SV_URL + "/getUserRole";
      const postParams = {
        user: this.credentials.getUser(),
      };

      this.utils
        .post(`${url}`, { pld: btoa(JSON.stringify(postParams)) })
        .subscribe({
          next: (res: any) => {
            switch (rotina) {
              case "premios_geral": {
                resolve(res[0].premios_geral ? true : false);
                break;
              }
              case "premios_lancamento": {
                resolve(res[0].premios_lancamento ? true : false);
                break;
              }
              case "descarga_lancamento": {
                resolve(res[0].descarga_lancamento ? true : false);
                break;
              }
              case "deu": {
                resolve(res[0].deu ? true : false);
                break;
              }
              case "folha": {
                resolve(res[0].folha ? true : false);
                break;
              }
              case "vendedores": {
                resolve(res[0].vendedores ? true : false);
                break;
              }
              case "rcc": {
                resolve(res[0].rcc ? true : false);
                break;
              }
              case "folgas": {
                resolve(res[0].folgas ? true : false);
                break;
              }
              case "envio": {
                resolve(res[0].envio ? true : false);
                break;
              }
              case "cheque": {
                resolve(res[0].cheque ? true : false);
                break;
              }
              case "pecas_geral": {
                resolve(res[0].pecas_geral ? true : false);
                break;
              }
              case "pecas_lancamento": {
                resolve(res[0].pecas_lancamento ? true : false);
                break;
              }
              case "rela_vendedores": {
                resolve(res[0].rela_vendedores ? true : false);
                break;
              }
              case "rela_premios": {
                resolve(res[0].rela_premios ? true : false);
                break;
              }
              case "rela_planilha_lot": {
                resolve(res[0].rela_planilha_lot ? true : false);
                break;
              }
              case "horarios": {
                resolve(res[0].horarios ? true : false);
                break;
              }
              case "mapa": {
                resolve(res[0].mapa ? true : false);
                break;
              }
              case "resultados": {
                resolve(res[0].resultados ? true : false);
                break;
              }
              case "grupo": {
                resolve(res[0].grupo);
                break;
              }
              case "fotos": {
                resolve(res[0].fotos);
                break;
              }
              case "astuser": {
                resolve(res[0].astuser);
                break;
              }
              case "cambio": {
                resolve(res[0].cambio);
                break;
              }
              case "rela_premios_data": {
                resolve(res[0].rela_premios_data);
                break;
              }
              case "master": {
                resolve(res[0].master);
                break;
              }
            }
          },
          error: () => {
            sessionStorage.removeItem("portal");
            sessionStorage.removeItem("uid");
            sessionStorage.removeItem("x");
            this.router.navigate(["/pages/authLogin/login"]);
            window.location.reload();
          },
        });
    });
  }

  edit(
    id,
    premios_geral,
    premios_lancamento,
    rcc,
    deu,
    vendedores,
    envio,
    cheque,
    folha,
    folgas,
    pecas_lancamento,
    pecas_geral,
    rela_vendedores,
    rela_plan,
    rela_prem,
    deuAut,
    envioAut,
    premioAut,
    chequeAut,
    horario,
    data,
    userCreate,
    result_lancamento,
    mapa_lancamento
  ) {
    return new Promise<void>((resolve) => {
      const url: string = SV_URL + "/editUserRole";

      const postParams = {
        id: id,
        premios_geral: premios_geral,
        premios_lancamento: premios_lancamento,
        rcc: rcc,
        deu: deu,
        vendedores: vendedores,
        envio: envio,
        cheque: cheque,
        folha: folha,
        folgas: folgas,
        pecas_lancamento: pecas_lancamento,
        pecas_geral: pecas_geral,
        rela_vendedores: rela_vendedores,
        rela_plan: rela_plan,
        rela_premios: rela_prem,
        aut_deu: deuAut,
        aut_cheque: chequeAut,
        aut_envio: envioAut,
        aut_premio: premioAut,
        horarios: horario,
        data: data,
        userCreate: userCreate,
        result_lancamento: result_lancamento,
        mapa_lancamento: mapa_lancamento,
      };

      this.http.put(url, postParams).subscribe(
        (res) => {
          console.log("Enviado Com Sucesso");
        },
        (error) => {
          console.log(error);
        }
      );
      resolve();
    });
  }



  create(
    id,
    premios_geral,
    premios_lancamento,
    rcc,
    deu,
    vendedores,
    envio,
    cheque,
    folha,
    folgas,
    pecas_lancamento,
    pecas_geral,
    rela_vendedores,
    rela_plan,
    rela_prem,
    deuAut,
    envioAut,
    premioAut,
    chequeAut,
    horario,
    data,
    userCreate,
    result_lancamento,
    mapa_lancamento
  ) {
    return new Promise<void>((resolve) => {
      const url: string = SV_URL + "/createUserRole";

      const postParams = {
        userid: id,
        premios_geral: premios_geral,
        premios_lancamento: premios_lancamento,
        rcc: rcc,
        deu: deu,
        vendedores: vendedores,
        envio: envio,
        cheque: cheque,
        folha: folha,
        folgas: folgas,
        pecas_lancamento: pecas_lancamento,
        pecas_geral: pecas_geral,
        rela_vendedores: rela_vendedores,
        rela_planilha_lot: rela_plan,
        rela_premios: rela_prem,
        aut_deu: deuAut,
        aut_cheque: chequeAut,
        aut_envio: envioAut,
        aut_premio: premioAut,
        horarios: horario,
        data: data,
        userCreate: userCreate,
        result_lancamento: result_lancamento,
        mapa_lancamento: mapa_lancamento,
      };

      this.http.post(url, postParams).subscribe(
        (res) => {
          console.log("Enviado Com Sucesso");
        },
        (error) => {
          console.log(error);
        }
      );
      resolve();
    });
  }
}
