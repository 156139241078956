import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { SV_URL } from '../@core/utils/utils';

@Injectable()
export class FolgaProvider {

  public id: string;
  constructor(public http: HttpClient) {}

  saveFolga(data, desc, user, alt) {
    const url: string = SV_URL + '/postDataFolga';

    const postParams = {
      data: data,
      desc: desc ,
      alt_user: user,
      alt_data: alt,
    };

    this.http.post(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

  putFolga(id, data, desc, user, alt) {
    const url: string = SV_URL + '/putDataFolga';

    const postParams = {
      id: id,
      data: data,
      desc: desc ,
      alt_user: user,
      alt_data: alt,
    };

    this.http.put(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

}

