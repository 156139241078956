import { SV_URL_AST, SV_URL_SOCKET } from './../@core/utils/utils';
import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';

import { Observable } from 'rxjs/Observable';
import * as Rx from 'rxjs/Rx';
import { SV_URL } from '../@core/utils/utils';

@Injectable()
export class WebsocketService {

  private socket;

  constructor() { }

  connect(): Rx.Subject<MessageEvent> {
    this.socket = io(SV_URL_SOCKET, {
      transports: ["websocket"]
    });

    const observable = new Observable(observer => {
      this.socket.on('message', (data) => {
        observer.next(data);
      });
      this.socket.on('task', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });

    const observer2 = {
      next: (data: Object) => {
        this.socket.emit('message', JSON.stringify(data));
      },
    };

    return Rx.Subject.create(observer2, observable);
  }

connectAST(): Rx.Subject < MessageEvent > {
  this.socket = io(SV_URL_AST);

  const observable = new Observable(observer => {
    this.socket.on('message', (data) => {
      data['origin'] = 'message';
      observer.next(data);
    });
    this.socket.on('newphoto', (data) => {
      data['origin'] = 'newphoto';
      observer.next(data);
    });
    this.socket.on('newchange', (data) => {
      data['origin'] = 'newchange';
      observer.next(data);
    });
    return () => {
      this.socket.disconnect();
    };
  });

  const observer2 = {
    next: (data: Object) => {
      this.socket.emit('message', JSON.stringify(data));
    },
  };

  return Rx.Subject.create(observer2, observable);
}

}
