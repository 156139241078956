import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Subject } from 'rxjs/Rx';
import { WebsocketService } from './webSocketService';
@Injectable()
export class ChatService {

  messages: Subject<any>;

  constructor(
    private wsService: WebsocketService,
    private toastr: NbToastrService,
  ) {
    this.messages = <Subject<any>>wsService
      .connect()
      .map((response: any): any => {
        return response;
      });
   }

  sendMsg(msg) {
    this.messages.next(msg);
  }

  chatListener() {
    this.messages.subscribe(msg => {
      if (msg['to'] === atob(sessionStorage.getItem('uid'))) {
        this.toastr.show(msg['msg'], 'De:' + msg['from'], { preventDuplicates: true});
      }
    });
  }

}
