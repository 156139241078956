import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { SV_URL } from '../@core/utils/utils';

@Injectable()
export class FolhaProvider {

  public id: string;

  constructor(public http: HttpClient) {}

  post(cupom, seq, data, pc1, pc2, pc3, pc4, pc5, premio, deu) {
    const url: string = SV_URL + '/postFolhaPecas';

    const postParams = {
      cupom, seq, data, pc1, pc2, pc3, pc4, pc5, premio, deu,
    };

    this.http.post(url, postParams)
    .subscribe({
      next: () => {
        console.log('Enviado Com Sucesso');
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

}
