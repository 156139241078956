import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { SV_URL } from '../@core/utils/utils';

@Injectable()
export class ChequeProvider {

  public id: string;

  constructor(public http: HttpClient) {}

  saveCheque(data, cli, valor, cod, user, alt) {
    const url: string = SV_URL + '/postCheque';

    const postParams = {
      data: data,
      cli: cli ,
      valor: valor,
      cod: cod,
      user: user,
      alt: alt,
    };

    this.http.post(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }


  putCheque(id, data, cli, valor, cod, user, alt) {
    const url: string = SV_URL + '/putCheque';

    const postParams = {
      id: id,
      data: data,
      cli: cli ,
      valor: valor,
      cod: cod,
      user: user,
      alt: alt,
    };

    this.http.put(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

  putChequeStatus(id, cupom, status) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/putChequeStatus';

      const postParams = {
        id: id,
        cupom: cupom,
        status: status,
      };

      this.http.put(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');
        resolve();
        }, error => {
        console.log(error);
        resolve();
      });
    });

  }

  putChequeAutorizado(id, user, data, status) {
    const url: string = SV_URL + '/putChequeAutorizado';

    const postParams = {
      id: id,
      user: user,
      data: data,
      status: status,
    };

    this.http.put(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

}

