import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { SV_URL } from '../@core/utils/utils';

@Injectable()
export class SalesSimpliProvider {

  public id: string;
  constructor(public http: HttpClient) {}

  saveSalesSimpli(data, cli) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/postSalesBase';

      const postParams = {
        data: data,
        cli: cli,
      };

      this.http.post(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');

        return true;
        }, error => {
        console.log(error);
        return false;
      });
      resolve();
   });
  }

  putpc(id, pc, seq) {
    return new Promise<void>(resolve => {
      let url: string = '';

      switch (seq) {
        case '1': {
            url = SV_URL + '/putpc1';
            break;
        }
        case '2': {
          url = SV_URL + '/putpc2';
            break;
        }
        case '3': {
          url =  SV_URL + '/putpc3';
          break;
        }
        case '4': {
          url =  SV_URL + '/putpc4';
          break;
        }
        case '5': {
          url = SV_URL + '/putpc5';
          break;
        }
      }

      const postParams = {
        id: id,
        pc: pc ,
      };
      this.http.put(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');
        }, error => {
        console.log(error);
      });
      resolve();
    });
  }

  logPecas(data, cli, peca, valor, user, alt) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/logPecas';

      const postParams = {
        data: data,
        cli: cli,
        peca: peca,
        valor: valor,
        user: user,
        alt: alt,
      };

      this.http.post(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');

        return true;
        }, error => {
        console.log(error);
        return false;
      });
      resolve();
   });
  }

  logPecasLanca(data, horaI, horaF, seq,  user) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/logPecasLanca';

      const postParams = {
        data,
        horaI,
        horaF,
        seq,
        user,
      };

      this.http.post(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');

        return true;
        }, error => {
        console.log(error);
        return false;
      });
      resolve();
   });
  }

  getSalesCliData(cli, data) {
    return new Promise(resolve => {
      const url: string = SV_URL + '/getSalesCliData/' + cli + '/' + data;

      this.http.get(url)
      .subscribe(res => {
        resolve(res);
        return res;
        }, error => {
        console.log(error);
        return false;
      });
   });
  }

}

