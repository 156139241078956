import { SV_URL_AST } from './../@core/utils/utils';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { SV_URL } from '../@core/utils/utils';


@Injectable()
export class ServicesProvider {

  private urlPostSales: string = SV_URL + '/postSales';

  public id: string;
  constructor(public http: HttpClient) {
  }

  saveSales(data, cli, pc1, pc2, pc3, pc4, pc5) {

    const postParams = {
      data: data,
      cli: cli ,
      pc1: pc1,
      pc2: pc2,
      pc3: pc3,
      pc4: pc4,
      pc5: pc5,
    };

    this.http.post(this.urlPostSales, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      return true;
      }, error => {
      console.log(error);
      return false;
    });

  }

  sendMsg(msg, from, to) {
    const url = SV_URL + '/sendMsg';

    const postParams = {
      msg: msg,
      from: from ,
      to: to,
    };

    this.http.post(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

  saveSalesBasic(data, cli, pc, user, seq) {
    const urlPostSales: string = SV_URL + '/postSalesSimpli';

    const postParams = {
      data: data,
      cli: cli ,
      pc: pc,
      user: user,
      seq: seq,
    };

    this.http.post(urlPostSales, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      return true;
      }, error => {
      console.log(error);
      return false;
    });

  }


  saveSeller(cod, com, ret, status, dv, hv, ac, dt, tp, set, user, alt, com_mes, retido, parado) {
    const urlPostSellers = SV_URL + '/postSeller';

    const postParams = {
      cod: cod,
      com: com ,
      ret: ret,
      status: status,
      dv: dv,
      hv: hv,
      ac: ac,
      dt: dt,
      tp: tp,
      set: set,
      user: user,
      alt: alt,
      com_mes: com_mes,
      retido: retido,
      parado: parado,
    };

    this.http.post(urlPostSellers, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

  saveAppUser(cod, grupo, temp_pass, userCreate) {
    const urlPostSellers = SV_URL_AST + '/createUser';

    const postParams = {
      user: cod,
      grupo: grupo ,
      temp_pass: temp_pass,
      userCreate: userCreate,
    };

    this.http.post(urlPostSellers, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

  putAppUser(id, user, grupo) {
    const urlPostSellers = SV_URL_AST + '/putAppUser';

    const postParams = {
      id: id,
      user: user,
      grupo: grupo ,
    };

    this.http.put(urlPostSellers, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

  putSeller(id, cod, com, ret, status, dv, hv, ac, dt, tp, set, user, alt, com_mes, cupom, retido, parado) {
    const urlPostSellers = SV_URL + '/putSeller';

    // hv = hv >= 0? hv: 0

    const postParams = {
      id: id,
      cod: cod,
      com: com ,
      ret: ret,
      status: status,
      dv: dv,
      hv: hv,
      ac: ac,
      dt: dt,
      tp: tp,
      set: set,
      user: user,
      alt: alt,
      com_mes: com_mes,
      cupom: cupom,
      retido: retido,
      parado: parado,
    };

    this.http.put(urlPostSellers, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }


  changePwd(id, pwd, temp_pass) {
    const url: string = SV_URL_AST + '/changePwd';

    const postParams = {
      id: id,
      pwd: pwd,
      temp_pass: temp_pass,
    };

    this.http.put(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }

  createac(codigo, cli) {
    const url: string = SV_URL_AST + '/createAc';

    const postParams = {
      codigo,
      cli
    };

    this.http.post(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });
  }


  putStatus(id, status) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/putStatus';

      const postParams = {
        id: id,
        status: status ,
      };

      this.http.put(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');
      }, error => {
        console.log(error);
      });
      resolve();
    });
  }

  putDvAnt(cod, valor) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/putDvAnt';

      const postParams = {
        cod: cod,
        valor: valor ,
      };

      this.http.put(url, postParams)
      .subscribe(res => {
        console.log('alterou dv', cod, valor);
      }, error => {
        console.log(error);
      });
      resolve();
    });
  }

  putHvAnt(cod, valor) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/putHvAnt';

      const postParams = {
        cod: cod,
        valor: valor ,
      };

      this.http.put(url, postParams)
      .subscribe(res => {
        console.log('alterou hv', cod, valor);
      }, error => {
        console.log(error);
      });
      resolve();
    });
  }

  putCampoB(cod, valor) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/putCampoB';

      const postParams = {
        cod: cod,
        valor: valor ,
      };

      this.http.put(url, postParams)
      .subscribe(res => {
        console.log('alterou campo B', cod, valor);
      }, error => {
        console.log(error);
      });
      resolve();
    });
  }

  zeraSaldo(cod) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/zeraSaldo';

      const postParams = {
        cod: cod,
      };

      this.http.put(url, postParams)
      .subscribe(res => {
        console.log('zerou saldo', cod);
      }, error => {
        console.log(error);
      });
      resolve();
    });
  }

  putAcordo(cli, pc) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/putAcordo';

      const postParams = {
        cli: cli,
        pc: pc ,
      };

      this.http.put(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');
        }, error => {
        console.log(error);
      });
      resolve();
    });
  }

  putRetido(cod, valor) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/putRetido';

      const postParams = {
        cod: cod,
        valor: valor ,
      };

      this.http.put(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');
        }, error => {
        console.log(error);
      });
      resolve();
    });
  }

  putDataInicial(cli, dt, cupom) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/putDataInicial';

      const postParams = {
        cli: cli,
        dt: dt ,
        cupom: cupom,
      };

      this.http.put(url, postParams)
      .subscribe(res => {
        console.log('altera data inicial');
        }, error => {
        console.log(error);
      });
      resolve();
    });
  }

  putSales(id, data, cli, pc1, pc2, pc3, pc4, pc5) {

    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/putSales';

      const postParams = {
        id: id,
        data: data,
        cli: cli ,
        pc1: pc1,
        pc2: pc2,
        pc3: pc3,
        pc4: pc4,
        pc5: pc5,
      };

      this.http.put(url, postParams)
      .subscribe(res => {
        console.log('Enviado Com Sucesso');
      }, error => {
        console.log(error);
      });
      resolve();
    });
  }

  saveFolha(cupom, cli, data_inicial, data_final, deu_acordo, cor_desc, cor_val, ferias, comissao, premio, lucro, retorno, deve1, dv_ant, hv_ant, deve2, deu, deve3, dv_acordo, cheque, envio, comissaoMes) {
    const url: string = SV_URL + '/postFolha';

    const postParams = {
      cupom: cupom,
      cli: cli,
      data_inicial: data_inicial,
      data_final: data_final,
      deu_acordo: deu_acordo,
      cor_desc: cor_desc,
      cor_val: cor_val,
      ferias: ferias,
      comissao: comissao,
      premio: premio,
      lucro: lucro,
      retorno: retorno,
      deve1: deve1,
      dv_ant: dv_ant,
      hv_ant: hv_ant,
      deve2: deve2,
      deu: deu,
      deve3: deve3,
      dv_acordo: dv_acordo,
      cheque: cheque,
      envio: envio,
      comissaoMes: comissaoMes,
    };

    this.http.post(url, postParams)
    .subscribe(res => {
      console.log('Enviado Com Sucesso');
      }, error => {
      console.log(error);
    });

  }

  postConfPlan(data, pc, cli, user, user_alt) {
    return new Promise<void>(resolve => {
      const url: string = SV_URL + '/insertConfPlan';

      const postParams = {
        data: data,
        pc: pc,
        cli: cli,
        user: user,
        user_alt: user_alt,
      };

      this.http.post(url, postParams)
      .subscribe(res => {
        }, error => {
        console.log(error);
      });
      resolve();
    });
  }

}
