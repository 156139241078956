import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { ChatService } from '../../../services/chatService';
import { ServicesProvider } from '../../../services/services';


@Component({
  selector: 'ngx-dialog-template1',
  template: `
    <nb-card>
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
      <div class="col-sm-12 input-group">
        {{text}}
      </div>
      <br>
      <div class="col-sm-12 input-group">
          <textarea _ngcontent-c32="" class="form-control" [(ngModel)]="msg" placeholder="Digite Aqui sua Resposta" rows="3"></textarea>
        </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col-sm- ">
          <button class="btn btn-success" (click)="send()">Enviar</button>
        </div>
        <div class="col-sm-6 ">
          <button class="btn btn-danger" (click)="dismiss()">Sair</button>
        </div>
      </div>

    </nb-card-footer>
  </nb-card>
  `,
  styleUrls: ['./card-tarefas.component.scss'],
})
export class DialogTemplateComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() to: string;

  msg: string = '';
  from: string = atob(sessionStorage.getItem('uid'));

  constructor(
    protected ref: NbDialogRef<DialogTemplateComponent>,
    private provider: ServicesProvider,
    public http: HttpClient,
    public router: Router,
    public chat: ChatService,
    private toastr: NbToastrService,
    ) {}

  dismiss() {
    this.ref.close();
  }

  send() {
    this.provider.sendMsg(this.msg, this.from, this.to);
    this.toastr.success('Mensagem Enviada com Sucesso!' );
    this.dismiss();
  }
}
