import { SV_URL_AST } from './../@core/utils/utils';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { UtilsProvider } from './utils';

@Injectable()
export class SendAppProvider {

  public id: string;

  constructor(
    public http: HttpClient,
    public utils: UtilsProvider) {}

  sendMsg(cod, msg, tipo) {
    const url: string = SV_URL_AST + '/sendMsg';
    const data = new Date();
    const dataForm = `${data.getFullYear()}-${data.getMonth() + 1}-${data.getDate()} `;

    const postParams = {
      cod: cod,
      data: dataForm,
      msg: msg,
      tipo: tipo,
    };

    return this.utils.postHttpClient(url, postParams);

  }

  putChangeCheck(id, status, user, asid, cod, tipo, serie) {
    const url: string = SV_URL_AST + '/changeCheck';

    const postParams = {
      id, user, status, asid, cod, tipo, serie,
    };

    this.http.put(url, postParams)
    .subscribe({
      next: () => {
        console.log('Enviado Com Sucesso');
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}
