import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import "rxjs/add/operator/map";

import { SV_URL } from "../@core/utils/utils";
import { UtilsProvider } from "./utils";

@Injectable()
export class CambioProvider {
  public id: string;

  constructor(public http: HttpClient, public utils: UtilsProvider) { }

  saveCambioSeller(
    cod,
    nome,
    com,
    ant,
    set,
    comret_valor,
    comret_per,
    ativo,
    user,
    alt
  ) {
    const url: string = SV_URL + "/postCambioSeller";

    const postParams = {
      cod,
      nome,
      com,
      ant,
      set,
      comret_valor,
      comret_per,
      ativo,
      user,
      alt,
    };

    return this.utils.postHttpClient(url, postParams);
  }

  saveCambioFolha(
    data,
    cli,
    pecas,
    premios,
    comissao,
    deu,
    envio,
    lucro,
    anterior,
    final,
    comret,
    user,
    alt
  ) {
    const url: string = SV_URL + "/postCambioFolha";

    const postParams = {
      data,
      cli,
      pecas,
      premios,
      comissao,
      deu,
      envio,
      lucro,
      anterior,
      final,
      comret,
      user,
      alt,
    };

    return this.utils.postHttpClient(url, postParams);
  }

  finalizarPecas(cod) {
    const url: string = SV_URL + "/finalizaPecas";
    return this.utils.postHttpClient(url, { cod });
  }

  finalizarPremios(cod) {
    const url: string = SV_URL + "/finalizaPremios";
    return this.utils.postHttpClient(url, { cod });
  }

  finalizarDeu(cod) {
    const url: string = SV_URL + "/finalizaDeu";
    return this.utils.postHttpClient(url, { cod });
  }
  finalizarEnvio(cod) {
    const url: string = SV_URL + "/finalizaEnvio";
    return this.utils.postHttpClient(url, { cod });
  }

  atualizarAnterior(cli, anterior, comret) {
    const url: string = SV_URL + "/atualizarCambioAnterior";

    const postParams = {
      cli,
      anterior,
      comret,
    };

    return this.utils.postHttpClient(url, postParams);
  }

  getByCodData(cod, data) {
    const url: string = SV_URL + "/getCambioSellerData";

    const postParams = {
      cod: cod,
      data: data,
    };

    return this.utils.postHttpClient(url, postParams, true);
  }

  delete(id) {
    const url: string = SV_URL + "/deleteCambioSeller";

    const postParams = {
      id: id,
    };

    return this.utils.postHttpClient(url, postParams, false);
  }

  putCambioSeller(
    id,
    nome,
    com,
    ant,
    set,
    comret_valor,
    comret_per,
    ativo,
    user,
    alt
  ) {
    const url: string = SV_URL + "/putCambioSeller";

    const postParams = {
      id,
      nome,
      com,
      ant,
      set,
      comret_valor,
      comret_per,
      ativo,
      user,
      alt,
    };

    return this.utils.postHttpClient(url, postParams);
  }

  // postCambioBase(data,cli) {
  //   return new Promise(resolve => {
  //     var url:string = SV_URL + '/postCambioBase';

  //     let postParams = {
  //       data: data,
  //       cli: cli
  //     }

  //     this.http.post(url, postParams)
  //     .subscribe(res => {
  //       console.log('Enviado Com Sucesso');

  //       return true;
  //       }, error => {
  //       console.log(error);
  //       return false;
  //     });
  //     resolve();
  //  });
  // }

  postCambioPecas(data, cli, pc, seq, user, alt) {
    return new Promise<void>((resolve) => {
      const url: string = SV_URL + "/postCambioPeca";

      const postParams = {
        data,
        cli,
        pc,
        seq,
        user,
        alt,
      };

      this.http.post(url, postParams).subscribe(
        (res) => {
          console.log("Enviado Com Sucesso");

          return true;
        },
        (error) => {
          console.log(error);
          return false;
        }
      );
      resolve();
    });
  }

  postCambioPremio(data, cli, pc, seq, apos, user, alt) {
    return new Promise<void>((resolve) => {
      const url: string = SV_URL + "/postCambioPremio";

      const postParams = {
        data,
        cli,
        pc,
        seq,
        apos,
        user,
        alt,
      };

      this.http.post(url, postParams).subscribe(
        (res) => {
          console.log("Enviado Com Sucesso");

          return true;
        },
        (error) => {
          console.log(error);
          return false;
        }
      );
      resolve();
    });
  }

  postCambioDeu(data, cli, pc, valor, user, alt) {
    return new Promise<void>((resolve) => {
      const url: string = SV_URL + "/postCambioDeu";

      const postParams = {
        data,
        cli,
        pc,
        valor,
        user,
        alt,
      };

      this.http.post(url, postParams).subscribe(
        (res) => {
          console.log("Enviado Com Sucesso");

          return true;
        },
        (error) => {
          console.log(error);
          return false;
        }
      );
      resolve();
    });
  }

  postCambioEnvio(data, cli, pc, valor, tipo, user, alt) {
    return new Promise<void>((resolve) => {
      const url: string = SV_URL + "/postCambioEnvio";

      const postParams = {
        data,
        cli,
        pc,
        valor,
        tipo,
        user,
        alt,
      };

      this.http.post(url, postParams).subscribe(
        (res) => {
          console.log("Enviado Com Sucesso");

          return true;
        },
        (error) => {
          console.log(error);
          return false;
        }
      );
      resolve();
    });
  }

  putpcCambio(id, cli, pc, valor, data) {
    return new Promise<void>((resolve) => {
      let url: string = "";
      url = SV_URL + "/putpcCambio";

      const postParams = {
        id,
        cli,
        pc,
        valor,
        data,
      };
      this.http.put(url, postParams).subscribe(
        (res) => {
          console.log("Enviado Com Sucesso");
        },
        (error) => {
          console.log(error);
        }
      );
      resolve();
    });
  }

  putPremioCambio(id, cli, pc, valor, data) {
    return new Promise<void>((resolve) => {
      let url: string = "";
      url = SV_URL + "/putPremioCambio";

      const postParams = {
        id,
        cli,
        pc,
        valor,
        data,
      };
      this.http.put(url, postParams).subscribe(
        (res) => {
          console.log("Enviado Com Sucesso");
        },
        (error) => {
          console.log(error);
        }
      );
      resolve();
    });
  }

  putDeuCambio(id, cli, pc, valor, data) {
    return new Promise<void>((resolve) => {
      let url: string = "";
      url = SV_URL + "/putDeuCambio";

      const postParams = {
        id,
        cli,
        pc,
        valor,
        data,
      };
      this.http.put(url, postParams).subscribe(
        (res) => {
          console.log("Enviado Com Sucesso");
        },
        (error) => {
          console.log(error);
        }
      );
      resolve();
    });
  }

  putEnvioCambio(id, cli, pc, valor, data) {
    return new Promise<void>((resolve) => {
      let url: string = "";
      url = SV_URL + "/putEnvioCambio";

      const postParams = {
        id,
        cli,
        pc,
        valor,
        data,
      };
      this.http.put(url, postParams).subscribe(
        (res) => {
          console.log("Enviado Com Sucesso");
        },
        (error) => {
          console.log(error);
        }
      );
      resolve();
    });
  }

  salvarMensagem(id, msg) {
    return new Promise<void>((resolve) => {
      let url: string = "";
      url = SV_URL + "/putCambioMsg";

      const postParams = {
        id,
        msg,
      };
      this.http.put(url, postParams).subscribe(
        (res) => {
          console.log("Enviado Com Sucesso");
        },
        (error) => {
          console.log(error);
        }
      );
      resolve();
    });
  }
}
