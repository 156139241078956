import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { SV_URL } from '../@core/utils/utils';

import { Http} from '@angular/http';
import { UtilsProvider } from './utils';

@Injectable()
export class LoginProvider {

  public id: string;
  constructor(
    public http: Http,
    public httpClient: HttpClient,
    public utils: UtilsProvider) {
  }

  saveLogin(data, hora, user) {
      const url: string = SV_URL + '/postLog';

      const postParams = {
        data: data,
        hora: hora ,
        user: user,
      };

      return this.utils.postHttpClient(url, postParams);

  }

  login(user, pwd) {
      const url: string = SV_URL + '/login';

      const postParams = {
        user, pwd,
      };

      return this.utils.postHttp(url, postParams);

  }


}

